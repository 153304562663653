import React from "react";
import StoreManager from "../appdata";
import { callAPIBack } from "../api";
import Helpers, {screenIDState} from "../helpers";
import languageSDK from "../languages";

import TrueID from "../index"
import resource from "../resource";

export default class BackCardScreen extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.canvasRef = React.createRef();
        this.state = {
            width: window.innerWidth - 20, // Khởi tạo width ban đầu
            status: screenIDState.START, // 0 trạng thái ban đầu, 1 : calling api , 2: call api failed - hiển thị lỗi 
            canvasWidth: 800, // Giá trị ban đầu, sẽ cập nhật dựa vào kích thước thực tế
            canvasHeight: 600, // Giá trị ban đầu, sẽ cập nhật dựa vào kích thước thực tế
            errorMessage: ''
        };
    }

    componentDidMount() {
        StoreManager.appdata.thisBack = this
        if (StoreManager.appdata.media) {
            this.videoRef.current.srcObject = StoreManager.appdata.media;
        }

        // Cập nhật width khi cửa sổ được resize
        window.addEventListener('resize', this.updateDimensions);
        // Helpers.loading.drawLoadingSpinner()
    }

    componentWillUnmount() {
        // Dọn dẹp event listener khi component bị unmount

        window.removeEventListener('resize', this.updateDimensions);
        Helpers.loading.hideLoadingSpinner()
        
    }

    updateDimensions = () => {
        const width = window.innerWidth - 20;
        const height = width * 0.75; // Giả sử tỷ lệ khung hình là 0.65
        this.setState({
            width: width,
            canvasWidth: width,
            canvasHeight: height,
        });
    };

    actionButon = () =>{
        const { status, canvasWidth, canvasHeight } = this.state;
        if (status == screenIDState.CALLING_API) {
            return;
        }
        if (status == screenIDState.START) {
            this.capture()
        }
        if (status == screenIDState.CALL_API_FAILED) {
            this.resumeVideoStream()
            this.setState({errorMessage: '', status: screenIDState.START})
        }
    }
    pauseVideoStream = () => {
        const videoElement = this.videoRef.current;
        if (videoElement) {
            videoElement.pause();  // Sử dụng phương thức pause() của HTMLVideoElement
        }
    };
    
    resumeVideoStream = () => {
        const videoElement = this.videoRef.current;
        if (videoElement) {
            videoElement.play();  // Sử dụng phương thức play() để tiếp tục phát
        }
    };

    capture = () => {
        const { status, canvasWidth, canvasHeight } = this.state;
       
        const canvas = this.canvasRef.current;
        const video = this.videoRef.current;
        if (canvas && video) {
            canvas.width = canvasWidth; // Cập nhật kích thước của canvas
            canvas.height = canvasHeight;
            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvasWidth, canvasHeight);
            // console.log("canvasWidth, canvasHeight", canvasWidth, canvasHeight)
            const imageSrc = canvas.toDataURL('image/jpeg');
            // capturedImage: imageSrc 

            this.setState({
                status: screenIDState.CALLING_API
            }, ()=>{
                Helpers.loading.drawLoadingSpinner()
            });

            this.pauseVideoStream()
            callAPIBack(imageSrc.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', ''));
        }
    };


    updateSuccess = () =>{
        this.setState({status: screenIDState.UPDATE_SUCCESS}, () => {
            setTimeout(()=>{
                StoreManager.appdata.thisApp.nextStep()
            }, 1500)
        })
    }

    

    closeScreen = () => {
        StoreManager.appdata.callback({code: 0,errorMessage: "User Close SDK"})
        TrueID.close()
    };

    render() {
        const { width,  status, canvasWidth, canvasHeight, errorMessage } = this.state; // Sử dụng width từ state
        let configEkyc = StoreManager.appdata.configEkyc
        var subTitle = `${languageSDK.language.trueID_back_side_message}`
        var buttonTitle = `${languageSDK.language.trueID_take_photo}`
        if (status == screenIDState.CALL_API_FAILED) {
            subTitle = errorMessage
            buttonTitle = `${languageSDK.language.trueID_retake_photo}`
        }
        return (
            <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', position: 'relative', padding: '10px' }}>
                <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    <a onClick={this.closeScreen} style={{ fontSize: '16px', color: configEkyc.closeColor, backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}>
                        X
                    </a>
                </div>
                <div style={{ display: status === screenIDState.CALLING_API ? 'block' : 'none', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 }}>
                    <canvas id="loadingCanvas" width="50" height="50"></canvas>
                </div>
                <div style={{ display: status === screenIDState.UPDATE_SUCCESS ? 'block' : 'none', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1002 }}>
                    <img src={resource.images.iconsuccess} style={{width: 50, height: 50}} />
                </div>

                <p style={{ color: configEkyc.titleColor, fontSize: 20,  marginBottom: '20px', fontWeight: 600 }}>{`${languageSDK.language.trueID_back_side_photo}`}</p>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' ,marginBottom: '20px',}}>
                    <div style={{ width: width, height: width * 0.75, maxWidth: 600, maxHeight: 400, overflow: 'hidden', borderRadius: '10px', border: `5px solid ${ status == screenIDState.CALL_API_FAILED? '#CCCCCC33' : '#CCCCCC33' }`, position: 'relative' }}>
                        <video ref={this.videoRef} autoPlay playsInline style={{ width: '100%', height: '100%', objectFit: 'cover' }}></video>
                    </div>
                </div>
                <p style={{ color: configEkyc.titleColor, fontSize: 18}}>{`${subTitle}`}</p>
                <button onClick={this.actionButon} style={{ marginTop: '40px', fontSize: '18px', padding: '10px 20px', borderRadius: '5px', backgroundColor: configEkyc.buttonCaptureColor, color: configEkyc.titleButtonCaptureColor, border: 'none', cursor: 'pointer' }}>
                    {`${buttonTitle}`}
                </button>
                <canvas ref={this.canvasRef} style={{ display: 'none' }} width={canvasWidth} height={canvasHeight}></canvas>

            </div>
        );
    }
}
