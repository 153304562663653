import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import StoreManager from './appdata';


var root = null;
var container = null; // Khai báo biến container ở cấp độ cao hơn để dễ dàng truy cập

function start(configEkyc, callback) {

    StoreManager.appdata.configEkyc = configEkyc
    StoreManager.appdata.callback = callback

    container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.zIndex = 16777100;
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.top = '0';
    container.style.left = '0';
    container.style.right = '0';
    container.style.bottom = '0';    

 
    document.body.appendChild(container);

    root = ReactDOM.createRoot(container);
    root.render(
            <App />
    );
}

function close() {
  const tracks = StoreManager.appdata.media?.getTracks();
    tracks?.forEach((track) => {

    //   track.applyConstraints({
    //     advanced: [{ zoom: 1.0}]
    // }).then(() => {
    //     console.log('Zoom reset to default:');
    // }).catch(error => {
    //     console.error('Failed to reset zoom:', error);
    // });
      track.stop();
      
    });
    
    // console.log("tracks", tracks)
    StoreManager.appdata.media = null
    StoreManager.resetState()
    setTimeout(()=>{
      if (root) {
          root.unmount();
          container.parentNode.removeChild(container); // Sau đó xóa container div
          root = null;
          container = null;
      }
    }, 500)
   
}

export default { start, close };  // Xuất các hàm này như một phần của SDK


if (process.env.REACT_APP_ENV === 'development') {

          let  configEkyc = {
            domain:'/trueidapi',
            configEndpoint:{
                front : "/ekyc/v1.2/id/verify/front",
                back : "/ekyc/v1.2/id/verify/back",
                selfie : "/ekyc/v1.2/selfie/verify",
                complete : "",
                ocr : "/ekyc/v1.2/id/ocr",
                createrequest : "/ekyc/v1.2/request/create",
                accesstoken : "/auth/v1/oauth/accessToken"
            },
            clientId:"83e4ed801f0fd229c4129b1098d16e30",
            clientSecret: "vowGYZbL01elWEXOrsvqlQQ9BzJgjQ/4EFyeCeBCd2g=",
       
            configHeader:"{\"header1\":\"header1_value\", \"header2\":\"header2_value\"}",
            stepVerification: ["FRONT", "BACK","SELFIE", "EDITOCR"],
            titleColor: "#091E42CC",//80%
            subTitleColor: "#091E4299",//60%
            closeColor: "#091E42CC",//80%
            buttonCaptureColor:"#1182E7",
            titleButtonCaptureColor:"#ffffff",
            backgroundColor:"#ffffff",
            requestId:"",
            accessToken:""
        }
        let callBack = (result) => {
          console.log("SDK result", result)
               
          setTimeout(() => {
            start(configEkyc, (result)=>{
              console.log("SDK result 2", result)

            })
          }, 3000);
        }
       
        console.log("start")
        start(configEkyc, callBack)

}

// setTimeout(()=>{
//   close()
// }, 5000)