
import React from "react";
import ReactDOM from "react-dom";
import FrontCardScreen from './screens/FrontCardScreen';
import StoreManager from './appdata';
import BackCardScreen from './screens/BackCardScreen';
import TrueIDSelfieScreen from './screens/TrueIDSelfieScreen';
import EditOCRScreen from './screens/EditOCRScreen';

import { getAccessToken,callAPIComplete, callAPICompleteWithOCR } from './api';

export default class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      currentStep: '',
      height: '100vh',// window.innerHeight, // Khởi tạo width ban đầu
      productionKey: false,
    }
    this.hasMounted = false; // Thêm cờ ở đây

  }
  componentDidMount(){
    if (this.hasMounted) return; // Nếu đã chạy thì return

    this.hasMounted = true; // Đặt cờ thành true sau khi chạy lần đầu
    
    StoreManager.loadDataFromLocal()
    window.addEventListener('resize', this.updateDimensions);
    // console.log("componentDidMount")
    getAccessToken()

    StoreManager.appdata.thisApp = this
    setTimeout(()=>{
      // var modal = document.getElementById("trueid");
      // modal.style.display = "block";
      // console.log("modal", modal)
      this.onStart()
    }, 1000)
    
  }
  componentWillUnmount() {
      // Dọn dẹp event listener khi component bị unmount
      window.removeEventListener('resize', this.updateDimensions);
    
  }
  updateDimensions = () => {
      // this.setState({ height: window.innerHeight + 200 });
  }
  nextStep = () => {
    const { currentStep } = this.state
    let index = StoreManager.appdata.configEkyc.stepVerification.indexOf(currentStep)
    if(index != -1) {
      // console.log("index", index)
      if(index < StoreManager.appdata.configEkyc.stepVerification.length - 1){
        this.setState({currentStep: StoreManager.appdata.configEkyc.stepVerification[index + 1]})
      } else if (index >= StoreManager.appdata.configEkyc.stepVerification.length - 1) {
        // Call complete 
        let indexOCR = StoreManager.appdata.configEkyc.stepVerification.indexOf("EDITOCR")
        StoreManager.stopStream()
        if(indexOCR != -1){
          // console.log("Call complete with ocr")
          callAPICompleteWithOCR()
        } else {
          // console.log("Call complete")
          callAPIComplete()
        }
        
      } 
    }
  

  }
  onStart = () =>{
        // this.setState({ isCheckMedia: true });

        StoreManager.loadMediaStream().then(() => {
          this.setState({currentStep: StoreManager.appdata.configEkyc.stepVerification[0]})

        }).catch((error) => {
          console.error("Failed to load media stream: ", error);
        });
  }


  render(){
    const {currentStep, height} = this.state
    var content =  <></>
    switch (currentStep) {
      case "FRONT":
        content = <FrontCardScreen/>
        break;
      case "BACK":
        content = <BackCardScreen/>
        break;
      case "SELFIE":
          content = <TrueIDSelfieScreen/>
          break;
      case "EDITOCR":
        content = <EditOCRScreen/>
        break;
      default:
        <></>
        break;
    }

    return  <div id="trueid" style={{backgroundColor: StoreManager.appdata.configEkyc.backgroundColor, flex: 1, height: height}}>
    {
      content
    }
    <p hidden id="status">Initializing...</p>
</div>
  }

}

